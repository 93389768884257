<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error" v-loading="loading">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <custom-header :title="'Брэнд үүсгэх'"/>
        <el-form label-position="top" :model="brandData" :rules="brandRules" ref="brandData">
          <el-row :gutter="20">
            <el-col :span="20">
              <div class="panel">
                <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Монгол нэр" prop="brand_monName">
                        <el-input v-model="brandData.brand_monName" placeholder="Говь ХХК ..."></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="Англи нэр" prop="brand_engName">
                      <el-input v-model="brandData.brand_engName" placeholder="Говь ХХК ..."></el-input>
                    </el-form-item>
                  </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item label="Нийлүүлэгч" prop="supplier_id">
                        <el-select
                          v-model="brandData.supplier_id"
                          filterable
                          class="block">
                          <el-option
                            v-for="item in supplierOption"
                            :key="item.supplier_id"
                            :label="item.supplier_monName"
                            :value="item.supplier_id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Шимтгэлийн хувь" prop="fee_percent">
                        <el-input v-model="brandData.fee_percent" disabled placeholder="Шимтгэлийн хувь"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row :gutter="20">
                    <!-- <el-col :span="12">
                      <el-form-item label="Төлөв">
                        <el-switch
                          style="display: block"
                          v-model="brandData.isActive"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-text="Идэвхитэй"
                          inactive-text="Идэвхгүй"
                          class="mt10 text-center">
                        </el-switch>
                      </el-form-item>
                    </el-col> -->
                    <el-col :span="12">
                      <el-form-item label="Лого зураг">
                        <el-upload
                          list-type="picture-card"
                          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
                          :limit="1"
                          action=""
                          :file-list="tempImages"
                          :auto-upload="false"
                          :on-change="(file) => getFile(file,'image')"
                          :on-remove="(file) => handleRemove(file, 'image')">
                          <span>250x250</span>
                        </el-upload>
                        <loader :active="isUploading" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item label="Нийлүүлэгчийн брэнд">
                        <el-input placeholder="Нийлүүлэгчийн брэнд ID ..." v-model="brandData.supplier_brand_id"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-form-item label="Тайлбар" prop="brand_description">
                        <el-input v-model="brandData.brand_description" placeholder="Улс, түүх"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="20" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true">Болих</el-button>
                <el-button type="success" @click="onPressNewBrand('brandData', 'brandRules')"
                >Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/brandList">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import services from '../../../helpers/services'
import customHeader from '../../../components/common/customHeader'
import { handlers } from '../../../helpers/custom'
import loader from '../../../components/common/loader'
export default {
  components: {
    customHeader: customHeader,
    loader: loader
  },

  created () {
    this.getSuppliers()
  },

  data () {
    return {
      tempImages: [],
      isUploading: false,
      loading: false,
      confirmLeaveForm: false,
      supplierOption: [],
      brandList: [],
      supplierBrand: [],
      errorArray: [],
      pageStatus: {
        error: false,
        message: ''
      },
      brandData: {
        brand_monName: '',
        brand_engName: '',
        supplier_id: '',
        brand_description: '',
        brand_logo: '',
        supplier_brand_id: ''
      },
      brandRules: {
        brand_monName: [
          { required: true, message: 'Брэндийн монгол нэрээ оруулна уу', trigger: 'blur' }
        ],
        supplier_id: [
          { required: true, message: 'Нийлүүлэгчээ сонгоно уу', trigger: 'blur' }
        ],
        brand_engName: [
          { required: true, message: 'Брэндийн англи нэрээ оруулна уу', trigger: 'blur' }
        ],
        brand_description: [
          { required: true, message: 'Брэндийн тайлбараа оруулна уу', trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    handleRemove (file, type) {
      var tempId = 0
      if (type === 'image') {
        this.tempImages.forEach((element, index) => {
          if (element.uid === file.uid) {
            tempId = index
          }
        })
        this.tempImages.splice(tempId, 1)
      } else {
        this.$message.error('Уучлаарай алдаа гарлаа!')
      }
    },

    getFile (file, type) {
      handlers.checkImageSize(file, true, 250, 250).then(() => {
        this.isUploading = true
        handlers.getBase64(file.raw).then(res => {
          const tempBody = {
            image: res,
            filePath: file.name
          }
          services.imageUpload(tempBody).then(response => {
            this.isUploading = false
            if (response.status === 'success') {
              if (type === 'image') {
                this.tempImages.push({
                  url: response.url
                })
              }
            } else {
              this.$message.error('Уучлаарай алдаа гарлаа!')
            }
          })
        })
      }).catch((error) => {
        this.handleRemove(file, type)
        this.$message.error(error.message)
      })
    },
    onPressNewBrand (formName) {
      var tempImages = []
      this.tempImages.forEach(element => {
        tempImages.push(element.url)
      })
      this.brandData.brand_logo = tempImages
      this.brandData.isActive = 'active'
      this.$refs[formName].validate((valid) => {
        if (valid) {
          services.createBrand(this.brandData).then(data => {
            this.alertReporter(data.status === 'success' ? 'Амжилттай' : 'Уучлаарай', data.message, data.status)
            if (data.status === 'success') {
              this.$refs[formName].resetFields()
              this.$router.push({ name: 'brandList' })
            }
          })
        } else {
          return false
        }
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },

    getSuppliers () {
      const query = ''
      services.getSuppliers(query).then(data => {
        this.supplierOption = data.data
      })
    }
  }

}
</script>

<style></style>
